import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from "react-cookie"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "";

const Toolbox = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Submit the services you are interested and get a special offer 🎁" extraFields="services" amplitude={appState.amplitude} amplitudeClickEvent="ToolsSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="toolbox" title="Grow your business with EVVA tools" description="Accelerate your business with our allies and tools for Startups, SMEs and Freelancers."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
      {!cookies.consent &&
      <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
      }
      <MainSection title="Accelerate your business with our partners and toolbox" position="left" image="hero-toolbox.jpg" actionLabel="Start bank account" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
        <AppBar pathname={appState.path}/>
      </MainSection>
      <PageSection maxColumns="2" title="Our partners and tools for Startups, SMEs and Freelancers">
        <Card
            subtitle="Bind Cloud ERP"
            description="<p>The easiest and most complete cloud management system to grow your businesss.</p>"
            descriptionColor="#363636"
            image="bind-icon.png"
            actionLabel="I'm interested"
            action={launchFormHandler}
            link="https://www.bind.com.mx/"
            linkLabel="Learn more"
            amplitude={appState.amplitude}
            amplitudeClickEvent="ClickCloudErpInterest"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Mifiel Digital Signature"
            description="<p>Streamline your business safely and easily. Digitally sign any legal document in Mexico.</p>"
            descriptionColor="#363636"
            image="mifiel-icon.png"
            actionLabel="I'm interested"
            action={launchFormHandler}
            link="https://www.mifiel.com/es/"
            linkLabel="Learn more"
            amplitude={appState.amplitude}
            amplitudeClickEvent="ClickDigitalSignatureInterest"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Legal Advice"
            description="<p>Questions during the creation of your company? Learn the best legal form to set up your company.</p>"
            descriptionColor="#363636"
            image="legal-icon.png"
            actionLabel="I'm interested"
            action={launchFormHandler}
            link="/en/toolbox"
            linkLabel="Coming soon"
            amplitude={appState.amplitude}
            amplitudeClickEvent="ClickLegalAdviceInterest"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Digital Invoicing"
            description="<p>Issue and recover your business invoices directly from EVVA, everything stays under control.</p>"
            descriptionColor="#363636"
            image="invoice-icon.png"
            actionLabel="I'm interested"
            action={launchFormHandler}
            link="/en/toolbox"
            linkLabel="Coming soon"
            amplitude={appState.amplitude}
            amplitudeClickEvent="ClickInvoicingInterest"
            horizontal
            padding
            shadow
        />
      </PageSection>
      <SecondarySection
        title="Do you want to be part of the EVVA Business Community?"
        shortDescription="Let us help you to grow together."
        description="<p>Contact us directly on: <a href='mailto:info@evvafinanzas.com'>info@evvafinanzas.com<a/></p>"
        position="center"
        image="evva-contact.jpg"
        actionLabel="Get Early Access"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default Toolbox